@import url('https://fonts.google.com/specimen/Nunito+Sans&display=swap');

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--elements);
}

html {
  scroll-behavior: smooth;
}

#root {
  width: 100%;
  overflow: hidden;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  opacity: 1;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Nunito Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.4s ease-in-out, filter 0.4s ease-in-out, border 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

*:focus {
  outline: 0px;
}

h1,
h2,
h3,
h4,
p,
a {
  margin: 0;
  padding: 0;
  color: var(--text);
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  padding: 0.5rem 0rem;
}

h3 {
  font-size: 1.4rem;
}

h4 {
  font-size: 1.2rem;
}

p {
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5rem 0rem;
}

a {
  font-weight: 600;
}

button {
  cursor: pointer;
}