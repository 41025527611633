.backward {
    background-color: var(--elements);
    box-shadow: rgba(99, 99, 99, 0.05) 0px 1px 4px 0px;
    border: 1px solid var(--border);
    margin: 1.75rem 7vw 0rem 7vw;
    width: fit-content;
    border-radius: 0.25rem;

    &__link {
        width: fit-content;
        display: flex;
        padding: 0.1rem 2rem;
        flex-direction: row;

        & p {
            font-weight: 700;
        }
    }

    & svg {
        width: 1.75rem;
        padding-right: 0.5rem;
        fill: var(--text);
    }
}

.info {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0rem 7vw;
    height: 26rem;
    margin-bottom: 3rem;

    &__img {
        & img {
            width: 100%;
            box-shadow: rgba(99, 99, 99, 0.05) 0px 1px 4px 0px;
            border: 1px solid var(--border);
            border-radius: 0.25rem;
        }
    }

    &__container {
        padding: 1.5rem 0.5rem;

        & h2 {
            font-weight: 700;
        }

        & span {
            font-weight: 600;
        }
    }

    &__langs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__lang {
        font-weight: 600;
        margin: 0.25rem;
    }

    &__borders {
        display: flex;
        flex-direction: column;

        & p {
            font-weight: 600;
        }

        &-con {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__currs {
        display: flex;
        flex-wrap: wrap;
    }

    &__curr {
        font-weight: 600;

        &__name {
            padding: 0px;
        }
    }

    &__border {
        padding: 0.4rem 0.5rem;
        margin: 0.4rem;
        border-radius: 0.2rem;
        background-color: var(--elements);
        box-shadow: rgba(0, 0, 0, 0.025) 0px 1px 4px;
        border: 1px solid var(--border);

    }
}

.lang__name {
    padding: 0.5rem 0.5rem 0.4rem 0.5rem;
    margin: 0.25rem;
    border-radius: 0.2rem;
    background-color: var(--elements);
    box-shadow: rgba(0, 0, 0, 0.025) 0px 1px 4px;
    border: 1px solid var(--border);
}

.data {
    padding: 0.75rem 0px;

    & span {
        font-weight: 600;
    }
}

input[type="search"].dark {
    background: var(--text);
    color: var(--elements);
}

input[type="search"].light {
    color: var(--elements);
    color: var(--text);
}

input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 50em;
    margin-right: -0.1rem;
    background: url('../../assets/icons/cross.svg') no-repeat 75% 75%;
    background-size: contain;
    opacity: 0;
    pointer-events: none;
}

input[type="search"]:focus::-webkit-search-cancel-button {
    opacity: .3;
    pointer-events: all;
}

input[type="search"].dark::-webkit-search-cancel-button {
    filter: invert(1);
}

@media screen and (max-width:700px) {
    .info {
        padding: 2rem 7vw;
        height: fit-content;

        &__container {
            padding: 1.5rem 0.25rem;
        }
    }
}

@media screen and (min-width:700px) {
    .info {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1 1;

        &__img {
            width: 40%;
        }

        &__container {
            width: 50%;
            margin-left: auto;
            padding: 0px;

            & h2 {
                padding: 0.5rem 0px 0px 0px;
            }
        }

        &__borders {
            padding-top: 0.5rem;
        }
    }

    .data {
        &__holder {
            padding-top: 0.5rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1rem;
        }
    }
}

@media screen and (max-width:700px) {
    .data {
        & span {
            font-weight: 700;
        }
    }

    .info__lang {
        font-weight: 700;
    }

    .info__curr {
        font-weight: 700;
    }

    .info__borders p {
        font-weight: 700;
    }
}