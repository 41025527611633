.navbar {
    background-color: var(--elements);
    display: flex;
    flex-direction: row;
    padding: 0.5rem 5vw;
    box-shadow: rgba(99, 99, 99, 0.05) 0px 1px 4px 0px;
    border-bottom: 1px solid var(--border);

    a {
        margin: auto;
        margin-left: 0px;
    }

    p {
        font-size: 1.5rem;
        font-weight: 700;
        color: var(--text);
    }
}