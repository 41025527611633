.home {
    padding: 0.5rem 5vw 2rem 5vw;
}

.loader {
    width: 100%;
    height: 24rem;
    display: flex;
    justify-content: center;
}

.countries {
    width: 100%;
    display: flex;
    flex-direction: column;
    place-items: center;
    align-items: center;
}

.country {
    border-radius: 0.5rem;
    position: relative;
    padding-bottom: 0.4rem;
    box-shadow: rgba(99, 99, 99, 0.05) 0px 1px 4px 0px;
    border: 1px solid var(--border);
    background-color: var(--elements);
    margin: 1rem 0px;
    transition: transform 0.2s ease;

    &__loader {
        height: 28rem !important;
    }

    &__link {
        width: 90%;
    }

    &:hover {
        transform: scale(1.025);
        opacity: 0.8;
        cursor: pointer;
    }

    &__content {
        padding: 1rem 1.5rem;

        & h3 {
            font-weight: 700 !important;
            padding-bottom: 0.5rem;
        }

        & P {
            padding: 0.25rem 0px;
        }

        & span {
            font-weight: 600;
        }
    }

    &__img {
        width: 100%;
        height: 10rem;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 0.5rem 0.5rem 0px 0px;
    }
}

.search {
    padding: 1rem 0px;
    display: flex;
    flex-direction: row;

    & form {
        display: flex;
        flex-direction: row;
    }

    &__input,
    &__go {
        padding: 0.75rem 1rem;
        margin: 0.2rem;
        background-color: var(--elements);
        border: 0px;
        color: var(--text);
        box-shadow: rgba(99, 99, 99, 0.05) 0px 1px 4px 0px;
        border: 1px solid var(--border);
        border-radius: 0.4rem;
    }

    &__input {
        width: 100%;
    }
}

.filter {
    & p {
        margin: 0px 0.75rem;
        padding: 0px;
        padding-bottom: 0.25rem;
        text-transform: capitalize;
    }

    & span {
        font-weight: 600 !important;
    }
}

.MuiButton-root {
    margin: auto 0px auto auto !important;
    background-color: var(--elements) !important;
    color: var(--text) !important;
    font-weight: 400 !important;
    box-shadow: rgba(99, 99, 99, 0.05) 0px 1px 4px 0px !important;
    border: 1px solid var(--border) !important;
    border-radius: 0.4rem !important;
    text-transform: capitalize !important;
    padding: 0.5rem 1.75rem !important;
}

.MuiPaper-root {
    background-color: var(--elements) !important;
    color: var(--text) !important;
    font-weight: 400 !important;
    box-shadow: rgba(99, 99, 99, 0.05) 0px 1px 4px 0px !important;
    border: 1px solid var(--border) !important;
    border-radius: 0.4rem !important;
    text-transform: capitalize !important;
    border: 1px solid var(--border-line) !important;
}

.MuiMenuItem-root {
    padding: 0.25rem 1.75rem !important;
    min-height: 36px !important;
}

.MuiMenuItem-root {
    font-size: 1rem !important;
}

@media screen and (min-width: 700px) {
    .countries {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 0.75rem;
    }

    .search {
        padding: 1rem 0px;

        &__input {
            width: 35rem;
        }
    }
}

@media screen and (min-width: 1000px) {
    .countries {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 0.75rem;
    }
}

@media screen and (max-width: 700px) {
    .search {
        display: flex;
        flex-direction: column;
    }

    .MuiButton-root {
        margin: 0.5rem auto auto 0.25rem !important;
    }
}